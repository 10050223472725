<template>
  <div class="myhome">
    <div class="header">
      <van-cell-group :border="false">
        <van-cell :border="false" center is-link>
          <template #icon>
            <van-image
              width="60"
              height="60"
              lazy-load
              :src="info.avatar"
              fit="cover"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
          <template #title>
            <div class="shop_name">
              <div class="van-ellipsis">{{info.nickname}}</div>
              <van-tag v-if="info.rolename" type="primary" round style="margin-left: 5px;">{{info.rolename}}</van-tag>
            </div>
            <div class="shop_data">
              邀请码：{{info.agent_code}}
            </div>
          </template>
          <template #default>
            <div class="right_list" @click="teaminfo">
              设置
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="list_main">
      <van-grid>
        <van-grid-item @click="accountlog('credit1')"> 
          <template #icon>{{info.credit1}}</template>
          <template #text>积分</template>
        </van-grid-item>
        <van-grid-item @click="wallet"> 
          <template #icon>{{info.credit5}}</template>
          <template #text>联惠金</template>
        </van-grid-item>
        <van-grid-item @click="accountlog('credit3')"> 
          <template #icon>{{info.credit3}}</template>
          <template #text>活跃分</template>
        </van-grid-item>
        <van-grid-item @click="accountlog('credit4')"> 
          <template #icon>{{info.credit4}}</template>
          <template #text>惠宝</template>
        </van-grid-item>
      </van-grid>
      <van-cell-group :border="false">
        <van-cell title="我发布的转让" @click="zhuanmy" is-link center icon="records" />
      </van-cell-group>
    </div>
    <div class="logout">
      <van-button @click="logout" block type="danger" round>退出登录</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Myhome",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      xcx: 0
    }
  },
  mounted() {
    // this.init();
    console.log('ssss');
  },
  activated() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
    if (this.xcx == 0) {
      this.xcx = localStorage.getItem('xcx');
    }
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "team/user/get_info")
        .then(res => {
          if (res.data.code == 100000) {
            that.info = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 资料
    teaminfo() {
      this.$router.push({
        name: "Teaminfo",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 退出登录
    logout() {
      localStorage.setItem("SHOP_LOGIN", false);
      localStorage.removeItem("SHOP_UID");
      this.$router.replace({
        name: "Login",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 我发布的转让信息
    zhuanmy() {
      this.$router.push({
        name: "Zhuanmy",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 我发布的转让信息
    zhuanbuy() {
      this.$router.push({
        name: "Zhuanmy",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 明细
    accountlog(type) {
      this.$router.push({
        name: "Accountlog",
        query: {
          type: type,
          t: new Date().getTime()
        }
      })
    },

    // 奖金
    wallet() {
      this.$router.push({
        name: "Wallet",
        query: {
          t: new Date().getTime()
        }
      })
    },
    
  },
}
</script>
<style lang="less">
.myhome {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  // 头部
  .header {
    background: #f0f0f0;
    overflow: hidden;
    .van-cell {
      color: #333;
      background:  #fff;
      .van-image {
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid #ccc;
      }
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .shop_name {
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .shop_data {
          font-size: 12px;
          color: #333;
          display: flex;
          align-items: center;
          overflow: hidden;
          .shop_data_level {
            padding: 2px 5px;
            overflow: hidden;
            border-radius: 30px;
            margin-right: 5px;
            line-height: 10px;
            width: 65px;
            display: flex;
            align-items: center;
          }
        }
        .van-notice-bar {
          height: 24px;
          border-radius: 30px;
          font-size: 12px;
        }
      }
      .van-cell__value,
      .van-cell__right-icon {
        color: #333;
      }
    }
    .van-cell-group {
      background: #fff;
      margin: 10px 16px 0;
      overflow: hidden;
      border-radius: 6px;
      .van-cell {
        background: #fff;
        color: #333;
        .van-cell__title {
          padding: 0 10px;
        }
        .van-cell__right-icon {
          color: #333;
        }
      }
    }
  }
  // 按钮列表
  .list_main {
    overflow: hidden;
    .van-grid {
      margin: 10px 16px;
      overflow: hidden;
      border-radius: 6px;
      .van-grid-item__content {
        font-size: 14px;
        .van-grid-item__icon-wrapper {
          margin-bottom: 10px;
        }
      }
    }
    .van-cell-group {
      margin: 10px 16px;
      overflow: hidden;
      border-radius: 6px;
      .van-cell {
        line-height: 30px;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-cell__left-icon {
          font-size: 24px;
          color: #E83D31;
        }
        .van-cell__left-icon, .van-cell__right-icon {
          height: 30px;
          line-height: 30px;
        }
      }
      &:nth-of-type(2) {
        .van-cell {
          line-height: 30px;
          .van-cell__title {
            flex: 1;
            text-align: left;
          }
          .van-cell__left-icon {
            font-size: 24px;
            color: #44B659;
          }
        }
      }
    }
  }
  .logout {
    margin: 20px 16px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
  }
}
</style>